import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './App.css';
import L from 'leaflet';
import SearchBar from './SearchBar';

// Function to create custom icons
const createCustomIcon = (iconUrl) => {
  return new L.Icon({
    iconUrl: iconUrl,
    iconSize: [32, 32],
  });
};

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [points, setPoints] = useState([]);
  const [filteredPoints, setFilteredPoints] = useState([]);
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);

  // Datos iniciales de puntos con iconos únicos
  const initialPoints = [
    { id: 1, lat: 41.817, lng: 3.075, info: 'Local 1', icon: '/logos/logo1.png' },
    { id: 2, lat: 41.818, lng: 3.078, info: 'Local 2', icon: '/logos/logo2.png' },
    // Agregar más puntos según sea necesario
  ];

  useEffect(() => {
    // Simular carga inicial de puntos desde una fuente externa (por ejemplo, una API)
    setPoints(initialPoints);
    setFilteredPoints(initialPoints); // Mostrar todos los puntos al inicio
  }, []);

  // Manejador para cambios en el término de búsqueda
  const handleSearch = (term) => {
    setSearchTerm(term);

    // Filtrar puntos basado en el término de búsqueda
    const filtered = points.filter(point =>
      point.info.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredPoints(filtered);

    // Mostrar u ocultar los resultados según el término de búsqueda
    if (term.trim() !== '') {
      setSearchResultsVisible(true);
    } else {
      setSearchResultsVisible(false);
    }
  };

  return (
    <div className="App">
      <header>
        <div className="header-content">
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <nav className={`menu ${menuOpen ? 'active' : ''}`}>
            <ul>
              <li><a href="/">Inicio</a></li>
              <li><a href="/">Servicios</a></li>
              <li className="logo-container"><a href="/"><img src="/logo.png" alt="Logo" className="logo" /></a></li>
              <li><a href="/">Nosotros</a></li>
              <li><a href="/">Contacto</a></li>
              <li><a href="/">Ayuda</a></li>
            </ul>
          </nav>
        </div>
      </header>
      <main>
        <SearchBar handleSearch={handleSearch} />
        {searchResultsVisible && (
          <div className="search-results">
            {filteredPoints.length === 0 ? (
              <p>No se encontraron resultados</p>
            ) : (
              filteredPoints.map(point => (
                <div key={point.id} className="result-item">
                  <p>{point.info}</p>
                </div>
              ))
            )}
          </div>
        )}
        <MapContainer center={[41.817, 3.075]} zoom={15} style={{ height: '100%', width: '100%', position: 'relative' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {filteredPoints.map(point => (
            <Marker 
              key={point.id} 
              position={[point.lat, point.lng]} 
              icon={createCustomIcon(point.icon)}
            >
              <Popup>{point.info}</Popup>
            </Marker>
          ))}
        </MapContainer>
      </main>
    </div>
  );
}

export default App;
